// Components/Navigation.js
import React from 'react';
import { mapList } from '../mapdata/index';

const Navigation = ({ currentMap, onMapChange }) => {
  return (
    <nav>
      <ul className="flex space-x-4">
        {mapList.map((map) => (
          <li key={map.id}>
            <button
              type="button"
              onClick={() => onMapChange(map.id)}
              className={`px-4 py-2 ${
                currentMap === map.id ? 'text-blue-600' : 'text-gray-600'
              }`}
            >
              {map.title}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Navigation;