// mapdata/index.js
import { rp_richland } from './rp_richland';
import { dm_richland } from './dm_richland';
import { dm_richs_apt } from './dm_richs_apt';
import { dm_richs_crossfire } from './dm_richs_crossfire';
import { dm_richs_cube } from './dm_richs_cube';

const mapNames = [
  'rp_richland',
  'dm_richland',
  'dm_richs_apt',
  'dm_richs_crossfire',
  'dm_richs_cube',
];

const maps = {
  rp_richland,
  dm_richland,
  dm_richs_apt,
  dm_richs_crossfire,
  dm_richs_cube
};

const mapList = mapNames.map(name => ({
  id: name,
  title: maps[name].title
}));

export { maps, mapList };
