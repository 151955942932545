// MapData/dm_richs_apt.js
export const dm_richs_apt = {
    id: 'dm_richs_apt',
    title: "dm_richs_apt",
    publishDate: "Feb 5th, 2005",
    gamebananaId: 62774,
    description: " was the first hl2dm map I ever made. According to Wayback, it was up as early as Jan 2005.",
    files: [
      { name: 'dm_richs_apt.zip', type: 'zip' },
      { name: 'dm_richs_apt.bsp', type: 'bsp' }
    ],
    readme: `HL2DM Rich's Apartment
Best suited for 6-16 players.
Filename: dm_richs_apt.bsp
MODE D'EMPLOI:  Unzip the file "dm_richs_apt.bsp" to your 
"half-life 2 deathmatchhl2mpmaps" directory.
FEATURES:
- A 4 unit apartment building for great close-quarters combat.
- A laundry building and an open street area for when you need to get some air.
- Secret passages between all four apartments!  Hidden murder holes!
- Hidden locations that arent hard to find, but may give you an edge if you 
bother to use them.
KNOWN ISSUES:
- A few crashes, especially when reloading the map.  This could just be a 
problem with me only running one map, but who knows.  Your mileage may vary.
- It doesn't seem to work on a pure Linux Dedicated Server :-(  
Let me know if you have a different experience - i had to use the Windows DS.
- The buggy will not appear on dedicated servers without installing a 
vehicle patch, but this is not a problem with my map in particular.
Is this really your apartment?
Yeah, kinda.  I haven't made a map since Doom 2, so when I felt inspired to 
learn 3d mapping, I decided to start by creating the inside of my apartment.
When I made the map bigger, I recreated the design of my neighboring apts,
and created an exterior.  After playtesting this on my server for a while, I
made a few adjustments, cleaned it up, and a few versions later I have a
working deathmatch level thats lots of fun to play, and happens to bear a 
striking resemblance to a low-rent apartment complex in Iowa City, IA.
I'd like to thank you for downloading my map - I hope you enjoy it just as 
much as I enjoyed making it.  This is a fun map to explore, and a great 
deathmatch level.  There's something here for everybody, you just have to 
search it out.
Created by Rich McNinch
http://www.feralbeast.com/
Dedicated server address:  feralbeast.com`
  };
