// App.js
import React, { useState } from 'react';
import { maps, mapList } from './mapdata';
import Header from './components/Header';
import Footer from './components/Footer';
import MapGallery from './components/MapGallery';

const App = () => {
  const [currentMapId, setCurrentMapId] = useState(() => {
    if (!mapList || !mapList.length) {
      console.warn('mapList is empty or undefined');
      return 'default';
    }
    return mapList[0].id;
  });

  const currentMap = maps[currentMapId] || {
    id: 'default',
    title: 'Default Map',
    files: [],
    description: 'Map data is loading...',
    publishDate: '',
    readme: ''
  };

  const handleMapChange = (mapId) => {
    if (maps[mapId]) {
      setCurrentMapId(mapId);
    } else {
      console.warn(`Map with id ${mapId} not found`);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <Header currentMap={currentMapId} onMapChange={handleMapChange} />
      <main className="container mx-auto p-4 flex-grow">
        <MapGallery currentMap={currentMap} />
      </main>
      <Footer />
    </div>
  );
};

export default App;