// Components/Header.js
import React from 'react';
import Navigation from './Navigation';
import ServerStatus from './ServerStatus';
import { Popover } from '@headlessui/react';
import { maps } from '../mapdata';
import { 
  DocumentTextIcon,
  ArchiveBoxIcon, 
  DocumentIcon 
} from '@heroicons/react/24/outline';
import logo from '../images/feralbeast.svg';

const FileTypeIcon = ({ type }) => {
  switch(type?.toLowerCase()) {
    case 'zip':
      return <ArchiveBoxIcon className="h-5 w-5 mr-2 text-gray-500" />;
    case 'bsp':
      return <DocumentIcon className="h-5 w-5 mr-2 text-gray-500" />;
    default:
      return <DocumentTextIcon className="h-5 w-5 mr-2 text-gray-500" />;
  }
};

const Header = ({ currentMap: currentMapId, onMapChange }) => {
  const currentMap = maps[currentMapId];

  return (
    <header className="bg-gradient-to-r from-gray-900 to-gray-800 shadow">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex-shrink-0 flex items-center">
            <span className="font-['Rubik_Dirt'] text-4xl text-amber-500 mr-3 hover:text-amber-400 transition-colors">
              feralbeast
            </span>
            <img
              className="h-8 w-auto invert brightness-200 hover:brightness-125 transition-[filter]"
              src={logo}
              alt="Feral Beast"
            />
          </div>
          <Navigation currentMap={currentMapId} onMapChange={onMapChange} />

        </div>
      </div>

      {/* Map Details Section - Only show if currentMap exists */}
      {currentMap && (
        <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 border-t border-gray-700">
          <div className="flex flex-col gap-4">         
            {/* Title */}
            <h1 className="text-3xl font-bold text-gray-100">
              {currentMap.title || 'Map Gallery'}
            </h1>
            
            {/* Download buttons */}
            {currentMap.files && (
              <div className="flex flex-wrap gap-2">
                {currentMap.files.map((file) => (
                  <a 
                    key={file.name}
                    href={`/maps/${currentMapId}/${file.name}`} 
                    download
                    className="inline-flex items-center px-4 py-2 border border-gray-600 rounded-md shadow-sm text-sm font-medium text-gray-200 bg-gray-800 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 transition-colors duration-200"
                  >
                    <FileTypeIcon type={file.type} />
                    Download {file.name}
                  </a>
                ))}
              </div>
            )}

            {/* Metadata row */}
            <div className="text-sm text-gray-400 flex items-center gap-2">
              {currentMap.readme && (
                <Popover className="relative">
                  <Popover.Button className="inline-flex items-center text-gray-400 hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500">
                    <DocumentTextIcon className="h-5 w-5" />
                  </Popover.Button>

                  <Popover.Panel className="absolute z-10 w-[800px] mt-3 left-0">
                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                      <div className="relative bg-gray-800 p-4">
                        <h5 className="font-bold mb-2 text-gray-200">readme.txt</h5>
                        <pre className="bg-gray-900 p-4 rounded-lg overflow-x-auto max-h-96 overflow-y-auto">
                          <code className="text-sm font-mono text-gray-300 whitespace-pre-wrap">
                            {currentMap.readme}
                          </code>
                        </pre>
                      </div>
                    </div>
                  </Popover.Panel>
                </Popover>
              )}
              <span>Published: {currentMap.publishDate || 'Unknown date'}</span>
              {currentMap.gamebananaId && (
                <>
                  <span className="text-gray-500">•</span>
                  <a 
                    href={`https://gamebanana.com/mods/${currentMap.gamebananaId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center hover:text-amber-400 transition-colors"
                  >
                    <span className="mr-1">🍌</span>
                    View on GameBanana
                  </a>          <span className="text-gray-500">•</span> <ServerStatus />

                </>
              )}
            </div> 
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
