  // MapData/dm_richland.js
  export const dm_richland = {
    id: 'dm_richland',
    title: "dm_richland",
    publishDate: "July 1st, 2006",
    gamebananaId: 62773,
    description: " was a huge leap ahead and a labor of love. So many hours poured into this, trying every trick, recompiling and testing, adjusting, recompiling, testing, and so on. The native textures didn't have a lot to offer so I scoured home decor websites for patterns I could use. I had to replicate the popcorn ceiling, so I learned some bump-mapping and got the tile done, too. Not a shabby deathmatch map, and a real looker, too.",
    files: [
      { name: 'dm_richland.zip', type: 'zip' },
      { name: 'dm_richland.bsp', type: 'bsp' }
    ],
    readme: `Half-Life 2 Deathmatch: Richland Townhomes (dm_richland)
Instructions:  Put dm_richland.bsp in your "half-life 2 deathmatchhl2mpmaps" directory.
FEATURES:
* Deeply detailed apartment complex with two buildings, an office, playground, & swimming pool.
* 8 apartments are individually decorated to create their own unique personality and atmosphere.
* Secret passageways and underground tunnels interconnect the apartment, office and more.
* Deadly traps and hidden treasures to discover!
* All new textures make city17 look revitalized and new.
Custom Stuff:
- Virtually all indoor textures, posters and artwork.
- Music by The Slats (http://www.theslats.com).  Buy their awesome new CD "Boom Patrol".
- Stereo model by The LoveMeister
Created by Rich McNinch
http://www.feralbeast.com/
Dedicated server address:  feralbeast.com:27015
Send comments to webmeister@feralbeast.com`
  };