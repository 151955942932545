// MapData/rp_richland.js
export const rp_richland = {
    id: 'rp_richland',
    title: "rp_richland",
    publishDate: "September 21st, 2007",
    gamebananaId: 61107,
    description: " has had some surprising staying power and is the reason this website is still around. What can I say about it? I was always more of a deathmatch guy myself, but players on my server started spending more time playing with the features in the map. Some of them worked on special plugins to opt-out of deathmatch, and we spent a testing new features, really laying it on thick. I can't believe sometimes the number of references I see online, I'm just absolutely thrilled its still going.",
    files: [
      { name: 'rp_richland.zip', type: 'zip' },
      { name: 'rp_richlandday.bsp', type: 'bsp' },
      { name: 'rp_richlandnight.bsp', type: 'bsp' }
    ],
    videos: [
      {
        id: 'S-8L3_ZjpAM',
        title: 'RP Richland Video 1',
        url: 'https://www.youtube.com/watch?v=S-8L3_ZjpAM'
      },
      {
        id: 'bTG9okS4mYI',
        title: 'RP Richland Video 2',
        url: 'https://youtu.be/bTG9okS4mYI'
      },
      {
        id: 'Aqf5ogA0MO8',
        title: 'RP Richland Video 3',
        url: 'https://www.youtube.com/watch?v=Aqf5ogA0MO8'
      },
      {
        id: 'AOFnBu3pvrk',
        title: 'RP Richland Video 4',
        url: 'https://www.youtube.com/watch?v=AOFnBu3pvrk'
      },
      {
        id: '8zA7gYEdGeA',
        title: 'RP Richland Video 5',
        url: 'https://youtu.be/8zA7gYEdGeA'
      },
      {
        id: 'zusQ2M5bUy8',
        title: 'RP Richland Video 6',
        url: 'https://youtu.be/zusQ2M5bUy8'
      }
    ],
    readme: `Half-Life 2 Deathmatch: RP Richland Townhomes 
Based on the deathmatch map DM_Richland
Instructions:  Put rp_richlandday.bsp and rp_richlandnight.bsp in your 
"half-life 2 deathmatchhl2mpmaps" directory.
Theres nowhere else quite like Richland.  An isolated apartment complex
shrouded in mystery, worthy of exploration.  Will you discover its secrets?
New RP Features!
* Spawns placed outside of apartments, hidden passages removed.
* Weapon and item spawns placed discretely out of view.
* Lights on/off, Access to the Skybox, Doors lock and windows repair.
* Mobile TVs and Camera, Lightsabers, Weapon Zapper, Beds, Doorbells...
Custom Stuff:
- Virtually all indoor textures, posters and artwork.
- Music by The Slats (http://www.theslats.com)
- Stereo model by The LoveMeister
Special thanks to DrTimothy at http://www.phoneburnia.com for all his help.
Deathmatch Servers:
feralbeast.com:27015 (RP/Nokill Option)
feralbeast.com:27016 (DM Only)
Created by Rich McNinch
http://www.feralbeast.com/`
  };