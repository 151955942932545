// MapData/dm_richs_cube.js
export const dm_richs_cube = {
  id: 'dm_richs_cube',
  title: "dm_richs_cube",
  publishDate: "Feb 12th, 2005",
  gamebananaId: 62775,
  description: " was inspired by the phrase \"murder holes,\" a quote from the great John Romero back when id software was developing quake.",
  files: [
    { name: 'dm_richs_cube.zip', type: 'zip' },
    { name: 'dm_richs_cube.bsp', type: 'bsp' }
  ],
  readme: `HL2DM Rich's Cube
Best suited for 2-8 players.
Filename: dm_richs_cube.bsp
MODE D'EMPLOI:  Unzip the file "dm_richs_cube.bsp" to your 
"half-life 2 deathmatchhl2mpmaps" directory.
FEATURES:
- Cube shaped
- 4 floors
- Stuff to throw
- No place to hide
"This cube is small and stupid!  I am annoyed!"
I made this map in a few hours with the specific goal of making a quick 
and effective kill or be killed deathmatch level.  There is no fun in 
this level without death.  Yeah - its small.  Its a small boring cube.  
But its pretty, and it plays well enough.  If you want a tiny map that 
will rack up the kills quickly, you can't do much better than this.
Created by Rich McNinch
http://www.feralbeast.com/
Dedicated server address:  feralbeast.com`
};