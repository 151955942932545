// ServerStatus.js
import React from 'react';

const ServerStatus = () => {
  return (
    <div className="text-gray-300 text-sm">
      <h2 className="sr-only">Server Status</h2>
      <a href="https://www.gametracker.com/server_info/207.113.136.56:27015/" target="_blank" rel="noreferrer"><img src="https://cache.gametracker.com/server_info/207.113.136.56:27015/b_350_20_ffad41_e98100_000000_591f11.png" border="0" width="350" height="20" alt=""/></a>
    </div>
  );
};

export default ServerStatus;