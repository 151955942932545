const ImageGallery = ({ mapId }) => {
    const mapImages = [];
    try {
      const imageContext = require.context('../images', true, /\.(jpg|jpeg|png)$/i);
      for (const key of imageContext.keys()) {
        if (key.includes(`/${mapId}/`)) {
          mapImages.push({
            src: imageContext(key),
            alt: key.split('/').pop().split('.')[0]
          });
        }
      }
    } catch (error) {
      console.warn('No images found for', mapId);
    }
    if (!mapImages.length) return null;
  
    return (
      <div className="image-gallery my-8">
        <h2 className="text-2xl font-bold mb-4">Screenshots</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {mapImages.map((image) => (
            <div 
              key={image.alt}
              className="relative pt-[56.25%] overflow-hidden rounded-lg"
            >
              <img
                src={image.src}
                alt={image.alt}
                className="absolute inset-0 w-full h-full object-cover hover:scale-105 transition-transform duration-200"
                loading="lazy"
              />
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  export default ImageGallery;