  // MapData/dm_richs_crossfire.js
  export const dm_richs_crossfire = {
    id: 'dm_richs_crossfire',
    title: "dm_richs_crossfire", 
    publishDate: "April, 2008",
    gamebananaId: 62773,
    description: " -- hey look I just wanted to map a fun deathmatch map with a lot of weird features. This is that, and it's got a spooky pyramid that kills.",
    files: [
      { name: 'dm_richs_crossfire.zip', type: 'zip' },
      { name: 'dm_richs_crossfire.bsp', type: 'bsp' }
    ],
    readme: `Half-Life 2 Deathmatch: DM_Richs_Crossfire 
Instructions:  Put dm_richs_crossfire.bsp in your "half-life 2 deathmatchhl2mpmaps" directory.
This is just a quick and dirty deathmatch map that should bear some resemblance to dm_richs_apt, dm_richland, and rp_richland.  Two duplexes face off across a deadly landscape in an alien vessel of some sort.  I don't know... just kill stuff.
Custom Stuff:
- Virtually all indoor textures, posters and artwork.
Deathmatch Servers:
feralbeast.com:27015 (RP/Nokill Option)
Created by Rich McNinch
http://www.feralbeast.com/
webmeister@feralbeast.com`
  };