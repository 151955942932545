// components/MapGallery.js
import React from 'react';
import ImageGallery from './ImageGallery';
import VideoGallery from './VideoGallery';

const MapGallery = ({ currentMap }) => {
  if (!currentMap || !currentMap.files || !currentMap.files.length) {
    return <div>Loading map data...</div>;
  }

  return (
    <div className="map-gallery bg-gray-100 min-h-screen p-4">
      <div className="bg-white rounded-lg shadow-sm p-6 mb-4">
        <div className="page-content">
          <p>
            <strong>{currentMap.title || 'Untitled Map'}</strong> {currentMap.description || 'No description available'}
          </p>
        </div>
      </div>

      <div className="max-w-4xl mx-auto">
        <ImageGallery mapId={currentMap.id} />
        <VideoGallery videos={currentMap.videos} />
      </div>
    </div>
  );
};

export default MapGallery;